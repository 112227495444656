import React from "react";
import { InputLabel, MenuItem, Select, FormControl } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from '@components';

const SelectCustom = ({
  label,
  labelId,
  options,
  name,
  defValue = "",
  csx,
  icsx,
  rules,
  ...rest
}) => {
  const { register, formState: { errors } } = useFormContext();
  return (
    <>
      <FormControl variant='standard' fullWidth>
        <InputLabel id={labelId} sx={icsx}>
          {label}
        </InputLabel>
        <Select
          label={label}
          labelId={labelId}
          defaultValue={defValue}
          sx={csx}
          {...register(name, { ...rules })}
          {...rest}
        >
          {options.map(({ text, value }, key) => (
            <MenuItem value={value} key={key}>
              {text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {rules
        ? Object.keys(rules).map((error, i) => {
            return (
              errors &&
              errors?.[name]?.type === error && (
                <ErrorMessage
                  name={rules[error].message ? label : ""}
                  value={rules[error].value}
                  type={error}
                  key={i}
                />
              )
            );
          })
        : null}
    </>
  );
};

export default SelectCustom;
