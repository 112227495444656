import React, { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Image from "../images/Image";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { useScrollTrigger } from "@mui/material";
import { styled } from "@mui/material/styles";
import "./navbar.scss";
import Fab from "@mui/material/Fab";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { DrawerContext } from "@contexts/drawer/drawerContext";
import MenuCustom from "../menu/Menu";

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: "0 auto",
});

const pages = [
  { text: "HOME", href: "/home" },
  { text: "CHARACTERS", href: "/characters" },
  { text: "PERKS", href: "/perks" },
  { text: "BUILDS", href: "/builds" },
  { text: "ABOUT", href: "/about" },
];

const settings = [
  { text: "My Account", href: "/home" },
  { text: "My Builds", href: "/home" },
  { text: "Favorites", href: "/home" },
  { text: "Logout", href: "/home" },
];

const menuCharacters = [
  { text: "ALL", href: "/characters" },
  { text: "SURVIVORS", href: "/characters/survivors" },
  { text: "KILLERS", href: "/characters/killers" },
];

const menuPerks = [
  { text: "ALL", href: "/perks" },
  { text: "SURVIVORS", href: "/perks/survivors" },
  { text: "KILLERS", href: "/perks/killers" },
];

const Navbar = () => {
  const navigate = useNavigate();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
  });
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElCharacters, setAnchorElCharacters] = useState(null);
  const [anchorElPerks, setAnchorElPerks] = useState(null);
  const { handleDrawer, drawerContent } = useContext(DrawerContext);

  const menu = (direction, obj) => (
    <Box
      sx={{
        width: direction === "top" || direction === "bottom" ? "auto" : 250,
      }}
      role="presentation"
      onClick={toggleDrawer("bottom", false)}
      onKeyDown={toggleDrawer("bottom", false)}
    >
      <List>
        {obj.map((item, index) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton onClick={() => navigate(item.href)}>
              <ListItemIcon sx={{ color: "#fff" }}>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const toggleDrawer = (direction, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    handleDrawer({
      content: drawerContent.content,
      direction: direction,
      open: open,
    });
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenCharactersMenu = (event) => {
    if (anchorElCharacters !== event.currentTarget) {
      setAnchorElCharacters(event.currentTarget);
    }
  };

  const handleOpenPerksMenu = (event) => {
    if (anchorElPerks !== event.currentTarget) {
      setAnchorElPerks(event.currentTarget);
    }
  };

  const handleCloseCharactersMenu = () => {
    setAnchorElCharacters(null);
  };

  const handleClosePerksMenu = () => {
    setAnchorElPerks(null);
  };

  return (
    <AppBar
      className={`${trigger ? "box-shadow" : "not-shadow"}`}
      sx={{
        top: { xs: "auto", md: 0 },
        bottom: { xs: "-2px", md: "auto" },
        backgroundColor: {
          xs: "#010b0f",
          md: trigger ? "#010b0f" : "transparent",
        },
        transition: trigger ? "0.3s" : "0.5s",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          variant="dense"
          style={{
            transition: trigger ? "0.3s" : "0.5s",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "block" },
            }}
          >
            <Image
              fileName={`logo.svg`}
              folder={"utils"}
              alt={"logo"}
              style={{
                width: trigger ? "40px" : "80px",
                transition: trigger ? "0.3s" : "0.5s",
              }}
            />
          </Box>
          {/* Menu Desktop */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "space-around",
              p: "0 5rem",
            }}
          >
            {pages.map((page) => (
              <Link
                component="button"
                variant="body2"
                href={page.href}
                key={page.text}
                underline="none"
                onClick={() => navigate(page.href)}
                sx={{
                  my: 2,
                  color: "white",
                  display: "flex",
                  fontWeight: "bold",
                  textShadow: "2px 2px 1px #000",
                }}
                aria-owns={
                  anchorElCharacters
                    ? "simple-menu"
                    : anchorElPerks
                    ? "simple-menu-perks"
                    : undefined
                }
                aria-haspopup="true"
                onMouseOver={
                  page.text === "CHARACTERS"
                    ? handleOpenCharactersMenu
                    : page.text === "PERKS"
                    ? handleOpenPerksMenu
                    : null
                }
              >
                <Typography onClick={() => navigate(page.href)}>
                  {page.text}
                </Typography>
              </Link>
            ))}
            {/* Menu desplegable characters */}
            <MenuCustom
              id="simple-menu"
              anchorEl={anchorElCharacters}
              open={Boolean(anchorElCharacters)}
              onClose={handleCloseCharactersMenu}
              MenuListProps={{ onMouseLeave: handleCloseCharactersMenu }}
            >
              {menuCharacters.map((page) => (
                <MenuItem
                  key={page.text}
                  onClick={() => {
                    navigate(page.href);
                    handleCloseCharactersMenu();
                  }}
                >
                  {page.text}
                </MenuItem>
              ))}
            </MenuCustom>
            {/* Menu desplegable perks */}
            <MenuCustom
              id="simple-menu-perks"
              anchorEl={anchorElPerks}
              open={Boolean(anchorElPerks)}
              onClose={handleClosePerksMenu}
              MenuListProps={{ onMouseLeave: handleClosePerksMenu }}
            >
              {menuPerks.map((page) => (
                <MenuItem
                  key={page.text}
                  onClick={() => {
                    navigate(page.href);
                    handleClosePerksMenu();
                  }}
                >
                  {page.text}
                </MenuItem>
              ))}
            </MenuCustom>
          </Box>
          {/* Menu Mobile */}
          <Box
            sx={{
              flexGrow: { xs: 0, md: 1 },
              display: { md: "none", xs: "flex" },
              justifyContent: { xs: "flex-start", md: "space-around" },
              p: { xs: "0", md: "0 5rem" },
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() =>
                handleDrawer({
                  content: menu("bottom", pages),
                  open: true,
                  direction: "bottom",
                })
              }
            >
              <MenuIcon />
            </IconButton>
            <StyledFab
              sx={{ background: "#000", boxShadow: "0 .5rem 1rem #000" }}
              aria-label="add"
            >
              <Image
                fileName={`logo.svg`}
                folder={"utils"}
                alt={"logo"}
                style={{
                  width: "100%",
                }}
              />
            </StyledFab>
          </Box>

          <Box
            sx={{
              flexGrow: { xs: 1, md: 0 },
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Box
              className="box-avatar"
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Tooltip title="Open settings">
                <IconButton sx={{ p: 0 }}>
                  <Avatar alt="avatar" className="avatar">
                    <Box
                      sx={{ display: { xs: "none", md: "block" } }}
                      onClick={handleOpenUserMenu}
                    >
                      <Image
                        fileName={`icon.png`}
                        folder={"utils"}
                        alt={"logo"}
                        style={{ width: "30px" }}
                      />
                    </Box>

                    <Box
                      sx={{ display: { xs: "flex", md: "none" } }}
                      onClick={() =>
                        handleDrawer({
                          content: menu("bottom", settings),
                          open: true,
                          direction: "bottom",
                        })
                      }
                    >
                      <Image
                        fileName={`icon.png`}
                        folder={"utils"}
                        alt={"logo"}
                        style={{ width: "20px" }}
                      />
                    </Box>
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: {},
              }}
            >
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting.text} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting.text}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
