import { Menu } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import PropTypes from "prop-types";

const MenuStyle = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    background: "#010b0f",
    color: "#fff",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
      },
      "&:hover": {
        background: "rgba(255, 255, 255, .03)",
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const MenuCustom = ({ children, ...rest }) => {
  return (
    <>
      <MenuStyle {...rest}>{children}</MenuStyle>
    </>
  );
};

MenuCustom.propTypes = {
  children: PropTypes.node,
  rest: PropTypes.object,
};

export default MenuCustom;
