import React from "react";
import { Box, Typography } from "@mui/material";
import { useImage } from "@hooks";
import "@styles/loading.scss";

const Image = ({ fileName, folder, alt, className, csx, ...rest }) => {
  const { loading, error, image } = useImage(fileName, folder);

  if (error) return <Typography>{alt}</Typography>;

  return (
    <>
      {loading ? (
        <Box component="div" className="loading"></Box>
      ) : (
        <Box
          component="img"
          className={`Image${
            className ? className.padStart(className.length + 1) : ""
          }`}
          src={image}
          alt={alt}
          sx={csx}
          {...rest}
        />
      )}
    </>
  );
};

export default Image;
