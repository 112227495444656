import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './CommunityBuild.scss';


import { BuildServices } from "@services";
import BuildList from './BuildList';
import "./buildsView.scss";

const BuildViews = () => {


  const [myBuilds, setMyBuilds] = useState([]);
  const [topBuilds, setTopBuilds] = useState([]);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false); 
  let textSearchOwnBuilds = ''; 
  let textSearchTopBuilds = ''; 

  const loadData = async () => {
    setLoading(true);
    const { builds: ownBuilds } = await BuildServices.getBuilds({
      user: '631d4a1568ee8d09be606cc9',
    });
    const { builds: topBuilds } = await BuildServices.getTopBuilds();

    setMyBuilds(ownBuilds);
    setTopBuilds(topBuilds);
    setLoading(false);
  };

  const searchHandleOwnBuilds = async(text) => {
    textSearchOwnBuilds = text;
    const { builds: ownBuilds } = await BuildServices.getBuilds({
      user: '631d4a1568ee8d09be606cc9',
      name: text || undefined
    });
    setMyBuilds(ownBuilds);
  }

  const searchHandleTop = async (text) => {
    textSearchTopBuilds = text;
    const { builds: topBuilds } = await BuildServices.getTopBuilds({
      name: text || undefined
    });
    setTopBuilds(topBuilds);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    loadData();
  }, []);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  const filterRole = async(role, type) => {
    console.log({role, type})
    if(type === 'TOP') {
      const { builds: topBuilds } = await BuildServices.getTopBuilds({
        name: textSearchTopBuilds || undefined,
        role: role 
      });
      setTopBuilds(topBuilds);
    } else {
      const { builds: ownBuilds } = await BuildServices.getBuilds({
        user: '631d4a1568ee8d09be606cc9',
        name: textSearchOwnBuilds || undefined,
        role: role
      });
      setMyBuilds(ownBuilds);
    }
  }

  useEffect(() => {
    loadData();
  }, [value]);

  return ( 
    
    <Box display='flex' flexDirection='column'
        className="box-build"
        sx={{ maxWidth: '900px', mx: 'auto', color: '#FFF' }}
    >
      <Tabs 
          centered
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="full width tabs example"
          textColor="inherit"
          TabIndicatorProps={{
            style: { background: "red" },
          }}
          bgcolor={"#000"}
        >
          <Tab label="Top 10" {...a11yProps(0)} />
          <Tab label="My Builds"  {...a11yProps(1)} />
      </Tabs>
      
      {value===0 && 
        <Box sx={{color: 'white', marginTop: '30px'}}>
        <BuildList
          builds={topBuilds}
          filterRole={filterRole}
          searchHandler={searchHandleTop}
          loading={loading}
          type='TOP'
        />
      </Box>
      }

      {value===1 && 
        <Box sx={{color: 'white', marginTop: '30px' }}>
          <BuildList
            builds={myBuilds}
            filterRole={filterRole}
            searchHandler={searchHandleOwnBuilds}
            loading={loading}
            type='OWN'
          />
        </Box>
      }
    </Box>
    
  );
}
 
export default BuildViews;