import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { Image } from '@components';

const Sponsor = () => {
    const sponsorLinks = [
        { name: 'steam', href: 'https://store.steampowered.com/app/381210/Dead_by_Daylight/', width: { md: '130', xs: '65' } },
        { name: 'epic', href: 'https://www.epicgames.com/store/en-US/p/dead-by-daylight', width: { md: '30', xs: '22' } },
        { name: 'stadia', href: 'https://stadia.google.com/u/1/store/details/b67e43f2b05f4ba7acc56a4b222568aarcp1/sku/5af3426b8434401897bb4eeac0344662', width: { md: '160', xs: '80' } },
        { name: 'microsoft', href: 'https://www.microsoft.com/en-us/p/dead-by-daylight-windows/9nms4sfnbgbh?cid=msft_web_chart&activetab=pivot:overviewtab', width: { md: '92', xs: '45' } },
        { name: 'playstation', href: 'https://store.playstation.com/?resolve=EP3367-PPSA02049_00-CHAPTERECLIPSE00', width: { md: '150', xs: '120' } },
        { name: 'xbox', href: 'https://www.microsoft.com/en-us/p/dead-by-daylight-special-edition/c0n22p73qz60?activetab=pivot:overviewtab', width: { md: '185', xs: '125' } },
        { name: 'nintendo', href: 'https://www.nintendo.com/games/detail/dead-by-daylight-switch/', width: { md: '50', xs: '25' } },
    ];

    return (
        <>
            <Box component="div" sx={{background: '#0E0E0E'}}>
                <Typography component='h2' variant='h4' fontWeight={'bold'} color={'white'}
                    textAlign={'center'} pt={'2rem'}>Available on </Typography>
                <Box component='ul' display={'flex'} flexWrap={'wrap'} flexDirection={'row'}
                    justifyContent={'center'} alignItems={'center'} sx={{ listStyle: 'none', px: '1rem' }}>
                    {sponsorLinks.map(({ name, href, width }, i) => (
                        <Link
                            component='a'
                            href={href}
                            target='_blank'
                            key={name}
                            sx={{ ml: { xs: `${i === 0 ? '.5rem' : '2rem'}`, sm: `${i === 0 ? '2rem' : '2rem'}` }, mr: { xs: `${i === 0 ? '.5rem' : ''}`, sm: `${i === 0 ? '2rem' : ''}` } }}>
                            <Image fileName={`${name}.png`} folder={'utils/sponsors'} alt={name}
                                style={{ marginBottom: '2rem' }}
                                csx={{ width: { sm: `${width.md}px`, xs: `${width.xs}px` } }} />
                        </Link>
                    ))}
                </Box>
            </Box>
        </>
    );
}

export default Sponsor;