import { useEffect, useState } from 'react'

const useImage = (fileName, folder) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [image, setImage] = useState(null)

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = folder? await import(`@assets/${folder}/${fileName}`): null;
                setImage(folder? response.default: fileName);
            } catch (err) {
                setError(err)
            } finally {
                setLoading(false)
            }
        }

        fetchImage()
    }, [fileName, folder])

    return {
        loading,
        error,
        image,
    }
}

export default useImage