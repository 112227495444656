import React, { createContext } from "react";
import { DrawerMenu } from '../../components';
import { useDrawer } from "@hooks";


const DrawerProvider = ({ children }) => {
  const { handleDrawer, drawerContent } = useDrawer();
  return (
    <DrawerContext.Provider value={{ handleDrawer, drawerContent }}>
      <DrawerMenu />
      {children}
    </DrawerContext.Provider>
  );
};

export const DrawerContext = createContext();
export default DrawerProvider;

