import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { IconButton, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectFade } from "swiper";
import { CardComponent } from "@components";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import { CharacterServices } from "@services";
import { AppContext } from "@application";
import "./characterSection.scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

const CharacterSection = () => {
  const [characters, setCharacters] = useState([]);
  const [state, setState] = useContext(AppContext);
  const surviviorPrevRef = useRef(null);
  const surviviorNextRef = useRef(null);
  const killerPrevRef = useRef(null);
  const killerNextRef = useRef(null);

  const params = {
    spaceBetween: 30,
    slidesPerView: 3,
    slidesPerGroup: 3,
    loop: true,
    noSwiping: false,
    pagination: {
      // el: '.custom-pagination',
      // clickable: true,
      // // type: "fraction",
      // renderBullet: function (index, className) {
      //     return '<span class="' + className + '">' + (index + 1) + "</span>";
      // },
    },
    modules: [
      Navigation,
      // Pagination
    ],
    breakpoints: {
      0: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        noSwiping: true,
      },
      600: {
        slidesPerView: 2,
        slidesPerGroup: 1,
        noSwiping: true,
      },
      900: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        noSwiping: false,
      },
    },
  };

  const killers = [
    { name: "The Huntress", builds: 30, image: "huntress.svg" },
    { name: "The Huntress", builds: 30, image: "huntress.svg" },
    { name: "The Huntress", builds: 30, image: "huntress.svg" },
    { name: "The Huntress", builds: 30, image: "huntress.svg" },
    { name: "The Huntress", builds: 30, image: "huntress.svg" },
    { name: "The Huntress", builds: 30, image: "huntress.svg" },
  ];

  const loadData = async () => {
    const characterkData = await CharacterServices.getCharacters();
    setCharacters(characterkData.characters);
    setState({
      ...state,
      characters: characterkData.characters,
    });
  };

  const updateCharacters = (characters) => {
    setCharacters(characters);
  };

  useEffect(() => {
    const callCharacters = () => {
      const characters = state.characters;
      characters.length === 0 ? loadData() : updateCharacters(characters);
    };

    callCharacters();
  }, []);

  return (
    <>
      <Box component="div" className="characterSection">
        {/* Survivors */}
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
          justifyContent={"space-between"}
          mt={"5rem"}
        >
          <Box component="div" flexBasis={"20%"} p={"1rem"}>
            <Typography
              component="h2"
              color={"#fff"}
              sx={{
                textShadow: "1px 1px 1px #fff",
                typography: { xs: "h4", md: "h5", lg: "h4" },
              }}
            >
              Choose your favorite Survivor
            </Typography>
            <Box
              component="div"
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
            >
              <IconButton
                component="button"
                variant="contained"
                size="large"
                sx={{ color: "white" }}
                ref={surviviorNextRef}
              >
                <ChevronLeft fontSize="large" color={"white"} />
              </IconButton>

              <IconButton
                component="button"
                variant="contained"
                size="large"
                sx={{ color: "white" }}
                ref={surviviorPrevRef}
              >
                <ChevronRight fontSize="large" color={"white"} />
              </IconButton>
            </Box>
          </Box>
          <Box
            component="div"
            flexBasis={"80%"}
            sx={{
              maxWidth: { xs: "100%", md: "80%" },
              overflowX: "hidden",
              overflowY: "visible",
              p: "30px 1rem 2rem",
              mt: "2rem",
            }}
          >
            <Swiper
              {...params}
              navigation={{
                prevEl: surviviorPrevRef.current,
                nextEl: surviviorNextRef.current,
              }}
              //   pagination={{
              //     el: ".custom-pagination",
              //     clickable: true,
              //   }}
              effect={"creative"}
              creativeEffect={{
                prev: {
                  shadow: true,
                  translate: [0, 0, -400],
                },
                next: {
                  translate: ["100%", 0, 0],
                },
              }}
              className="mySwiper swiper-box"
            >
              {characters.length > 0
                ? characters
                    .filter((e) => e.role === "survivor")
                    .map((character) => (
                      <SwiperSlide key={`swi${character.id}`}>
                        <CardComponent
                          name={character.name}
                          builds={10}
                          image={character.slug}
                          perks={character.perks}
                          character={character}
                          type={character.role}
                          key={character.id}
                        />
                      </SwiperSlide>
                    ))
                : null}
              <Box component="div" className="custom-pagination" />
            </Swiper>
          </Box>
        </Box>
        {/* Killers */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            mt: { xs: "1rem", md: "3rem" },
            justifyContent: "space-between",
          }}
        >
          <Box
            component="div"
            flexBasis={"20%"}
            p={"1rem"}
            sx={{ order: { xs: 0, md: 1 } }}
          >
            <Typography
              component="h2"
              color={"#fff"}
              sx={{
                textShadow: "1px 1px 1px #fff",
                typography: { xs: "h4", md: "h5", lg: "h4" },
              }}
            >
              Choose your favorite Killer
            </Typography>
            <Box
              component="div"
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
            >
              <IconButton
                component="button"
                variant="contained"
                size="large"
                sx={{ color: "white" }}
                ref={killerNextRef}
              >
                <ChevronLeft fontSize="large" color={"white"} />
              </IconButton>

              <IconButton
                component="button"
                variant="contained"
                size="large"
                sx={{ color: "white" }}
                ref={killerPrevRef}
              >
                <ChevronRight fontSize="large" color={"white"} />
              </IconButton>
            </Box>
          </Box>
          <Box
            component="div"
            flexBasis={"80%"}
            sx={{
              maxWidth: "80%",
              overflowX: "hidden",
              overflowY: "visible",
              p: "30px 1rem 2rem",
              order: { xs: 1, md: 0 },
            }}
          >
            <Swiper
              {...params}
              navigation={{
                prevEl: killerPrevRef.current,
                nextEl: killerNextRef.current,
              }}
              className="swiper-box"
            >
              {characters.length > 0 &&
                characters
                  .filter((e) => e.role === "killer")
                  .map((character) => (
                    <SwiperSlide key={`swi${character.id}`}>
                      <CardComponent
                        name={character.name}
                        builds={10}
                        image={character.slug}
                        perks={character.perks}
                        character={character}
                        type={character.role}
                        key={character.id}
                      />
                    </SwiperSlide>
                  ))}
              <Box component="div" className="custom-pagination" />
            </Swiper>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CharacterSection;
