import { AppBar, Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import PerkSelector from "../builds/characterBuild/PerkSelector";

const PerksView = () => {
  const [valueTab, setValueTab] = useState(0);
  const handleChange = (event, newValue) => {
    setValueTab(newValue);
    // setCharacters([]);

    setTimeout(() => {
      // setCharacters(state.characters);
    }, 400);
  };

  const getRole = (role) => {
    return role === 1 ? "survivor" : "killer";
  };

  return (
    <Box sx={{ maxWidth: "900px", mx: "auto", mt: "7rem" }}>
      <AppBar position="static" sx={{ bgcolor: "transparent" }}>
        <Tabs
          centered
          value={valueTab}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="full width tabs example"
          textColor="inherit"
          TabIndicatorProps={{
            style: { background: "red" },
          }}
          bgcolor={"#000"}
        >
          <Tab label="All" />
          <Tab label="Survivors" />
          <Tab label="Killers" />
        </Tabs>
      </AppBar>
      <PerkSelector insidePage={false} />
    </Box>
  );
};

export default PerksView;
