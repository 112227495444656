import React, { useContext, useState } from "react";
import { Box, Button, styled, Typography, useMediaQuery } from "@mui/material";
import { Image, PerkDetail, DialogCustom, CustomTooltip } from "@components";
import { DrawerContext } from "@contexts/drawer/drawerContext";
import "./characterDetailView.scss";
import { grey } from "@mui/material/colors";
import { motion } from "framer-motion";

const ColorButton = styled(Button)(() => ({
    fontSize: '.65rem',
    color: grey[50],
    borderColor: grey[50],
    "&:hover": {
      borderColor: grey[600],
    },
  }));

const CharacterDetailView = ({ name, description, perks, role, slug }) => {
  const matchMd = useMediaQuery("(min-width:900px)");
  const { handleDrawer } = useContext(DrawerContext);
  const [detail, setDetail] = useState({
    name: "",
    detail: "",
    open: false,
  });

  const openModalPerk = ({ name = "", content = "", open = false }) => {
    setDetail({
      name,
      content,
      open,
    });
  };

  return (
    <>
      <Box
        component="div"
        pt={"3rem"}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          maxWidth: "1536px",
          margin: "auto",
          minHeight: "80vh",
        }}
        className="box-content"
        alignItems={"center"}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            flexBasis: { xs: "auto", md: "50%", lg: "40%" },
            order: { xs: 1, md: 0 },
            flexGrow: { xs: 0, md: 1 },
            p: { xs: "1rem", md: "4rem" },
            flexDirection: "column",
          }}
        >
          <Typography
            variant="body2"
            component="h3"
            color="white"
            sx={{
              fontWeight: "bold",
              typography: { md: "h2", xs: "h5" },
              textShadow: "5px 5px 5px #000",
            }}
          >
            {name}
          </Typography>

          <Typography
            variant="body2"
            component="h5"
            color="gray"
            sx={{
              mt: "1rem",
              textAlign: "justify",
              textShadow: "1px 3px 1px #000",
              typography: { xs: "h6", md: "h5" },
            }}
          >
            {description}
          </Typography>
          <Box
            component="div"
            py={"2rem"}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            {perks && perks.length > 0
              ? perks.map((perk, i) => (
                  <CustomTooltip
                    key={`Tool${perk.id}`}
                    title={
                      <Box
                        component={"div"}
                        display="flex"
                        sx={{ flexDirection: "column" }}
                        gap=".5rem"
                      >
                        <Typography component={"h3"} fontWeight='bold'>{perk.name}</Typography>
                        <Typography component={"h5"} variant="body2">
                          {perk.description}
                        </Typography>
                        <ColorButton
                          variant="outlined"
                          sx={{ ml: "auto", color: "#fff" }}
                          size="small"
                          onClick={() =>
                            matchMd
                              ? handleDrawer({
                                  content: (
                                    <PerkDetail
                                      name={perk.name}
                                      image={perk.id}
                                      detail={perk.description}
                                      width={"320px"}
                                    />
                                  ),
                                  open: true,
                                  direction: "left",
                                })
                              : openModalPerk({
                                  title: perk.name,
                                  content: perk,
                                  open: true,
                                })
                          }
                        >
                          Ver más
                        </ColorButton>
                      </Box>
                    }
                  >
                    <Box component="item-perk" key={i} >
                      <Image
                        fileName={perk.image}
                        className='cursor'
                        folder={""}
                        alt={perk.name}
                        key={`${i}md`}
                        csx={{
                          width: { xs: "100px", md: "80px" },
                          display: "flex",
                        }}
                        onClick={() =>
                          !matchMd
                            ? // version Desktop
                              openModalPerk({
                                name: perk.name,
                                content: perk,
                                open: true,
                              })
                            : // version Mobile
                              handleDrawer({
                                content: (
                                  <PerkDetail
                                    name={perk.name}
                                    image={perk.id}
                                    detail={perk.description}
                                    width={"320px"}
                                  />
                                ),
                                open: true,
                                direction: "left",
                              })
                        }
                      />
                    </Box>
                  </CustomTooltip>
                ))
              : null}
          </Box>
        </Box>
        <Box
          className={`box-character ${role}`}
          sx={{
            width: "100%",
            order: { xs: 0, md: 1 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexBasis: { xs: "auto", md: "50%", lg: "60%" },
          }}
        >
          {slug ? (
            <Image
            tra
              fileName={`${slug}.png`}
              folder={"characters/complete"}
              alt={name}
              csx={{ width: "100%", maxWidth: { xs: "100px", md: "220px" } }}
            />
          ) : null}
        </Box>
      </Box>

      <DialogCustom
        open={detail.open}
        handleClose={() => openModalPerk(false)}
        title={detail.name}
      >
        {detail ? (
          <PerkDetail
            name={detail.name}
            image={detail.content?.id}
            detail={detail.content?.description}
          />
        ) : null}
      </DialogCustom>
    </>
  );
};

export default CharacterDetailView;
