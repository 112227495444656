import { useState } from "react";

const useDrawer = () => {
    const [drawerContent, setDrawerContent] = useState({
        open: false,
        direction: 'bottom',
        content: false,
    });

    const handleDrawer = ({ content, open = false, direction = 'bottom', style = null }) => {
        setDrawerContent({ content: content, open: open, direction: direction, style: style });
    };

    return { handleDrawer, drawerContent };
};

export default useDrawer;