import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Image } from '@components';
import './principalSection.scss';
import 'swiper/css';


const PrincipalSection = () => {

    return (
        <>
            <Box component="div" className='section'
                sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center',}}>
                <Box component="div"
                    sx={{ display: 'flex', flexBasis: '35%', flexGrow: { xs: 0, md: 1 }, p: { xs: '1rem', md: '4rem' }, flexDirection: 'column' }}>
                    <Typography variant='h1' component="h1"
                        color='white' sx={{ typography: { md: 'h2', xs: 'h3' } }}>
                        Choose your best perks
                    </Typography>

                    <Typography variant="h5" component="h5"
                        color='gray' sx={{ mt: "1rem" }}>
                        Create your own builds and share
                        with your friends, rate other builds
                        and try with new combinations
                    </Typography>

                </Box>

                <Box component="div"
                    sx={{
                        flexGrow: { xs: 0, md: 1 }, flexBasis: '50%', textAlign: 'center', p: { xs: '1rem', md: '4rem' }
                    }}>
                    <Image fileName={'circle_perks.svg'} folder={'utils'} style={{ width: '100%', maxWidth: '450px', }} />
                </Box>
            </Box>
        </>
    );
}

export default PrincipalSection;