import React, { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { grey } from "@mui/material/colors";
import {
  Visibility as VisibilityIcon,
  Construction as ConstructionIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {
  Button,
  IconButton,
  InputBase,
  Link,
  Paper,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Image,
  DialogCustom,
  PerkDetail,
  Spinner,
  CustomTooltip,
  Sponsor,
} from "@components";
import { CharacterServices } from "@services";
import { CharacterDetailView, CharacterBuildView } from "@pages";
import { DrawerContext } from "@contexts/drawer/drawerContext";
import { AppContext } from "@application";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";

const ColorButton = styled(Button)(() => ({
  fontSize: ".65rem",
  color: grey[50],
  borderColor: grey[50],
  "&:hover": {
    borderColor: grey[600],
  },
}));

const CharactersView = () => {
  const matchMd = useMediaQuery("(min-width:900px)");
  const params = useParams();
  const [characters, setCharacters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [valueTab, setValueTab] = useState(0);
  const { handleDrawer } = useContext(DrawerContext);
  const [state, setState] = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [buildCharacter, setBuildCharacter] = useState({
    title: "",
    content: null,
    open: false,
  });

  const defaultContent = {
    name: "",
    id: "",
    description: "",
  };

  const [detail, setDetail] = useState({
    title: "",
    content: defaultContent,
    open: false,
  });
  const [detailPerk, setDetailPerk] = useState({
    title: "",
    content: defaultContent,
    open: false,
  });
  const openModal = ({ title = "", content = defaultContent, open = false }) =>
    setDetail({
      title,
      content,
      open,
    });

  const openModalPerk = ({
    title = "",
    content = defaultContent,
    open = false,
  }) =>
    setDetailPerk({
      title,
      content,
      open,
    });

  const openModalBuild = ({ title = "", content = "", open = false }) => {
    setBuildCharacter({
      title,
      content,
      open,
    });
  };

  const loadData = async () => {
    setLoading(true);
    const characterkData = await CharacterServices.getCharacters();
    setCharacters(characterkData.characters);
    setLoading(false);
    setState({
      ...state,
      characters: characterkData.characters,
    });
  };

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
    setCharacters([]);

    setTimeout(() => {
      setCharacters(state.characters);
    }, 400);
  };

  const getRole = (role) => {
    return role === 1 ? "survivor" : "killer";
  };

  const filterList = () => {
    return characters.filter(({ name }) =>
      name.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const clearAppBuilds = () => {
    openModalBuild(false);
    setState({
      ...state,
      build: {
        selected: [{}, {}, {}, {}],
        slotIndex: 0,
      },
    });
  };

  const updateCharacters = () => {
    setCharacters(state.characters);
  };

  const getParams = () => {
    const param = params.id;
    let response = 0;
    if (params.id) {
      response = param === 'survivors'? 1: param === 'killers'? 2 : 0;
    }
    setValueTab(response);
  };

  useEffect(() => {
    const callCharacters = () => {
      const characters = state.characters;
      characters.length === 0 ? loadData() : updateCharacters();
    };

    callCharacters();
    getParams();
  }, []);

  const containerMotion = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemMotion = {
    show: (i) => ({
      opacity: 1,
      scale: 1,
      x: 0,
      transition: {
        delay: i * 0.1,
      },
    }),
    hidden: { opacity: 0, scale: 0, x: "100%" },
  };

  return (
    <>
      <Box mb="2rem">
        {loading ? (
          <Box
            alignItems="center"
            justifyContent="center"
            display="flex"
            mt="5rem"
            height="60vh"
          >
            <Spinner />
          </Box>
        ) : (
          <>
            <AppBar
              position="static"
              sx={{ bgcolor: "transparent", maxWidth: "900px", mx: "auto" }}
            >
              <Tabs
                centered
                value={valueTab}
                onChange={handleChange}
                variant="fullWidth"
                aria-label="full width tabs example"
                textColor="inherit"
                TabIndicatorProps={{
                  style: { background: "red" },
                }}
                bgcolor={"#000"}
              >
                <Tab label="All" />
                <Tab label="Survivors" />
                <Tab label="Killers" />
              </Tabs>
            </AppBar>
            <Box pt="1rem" display="flex" sx={{ background: "#000b0f" }}>
              <Paper
                component="form"
                sx={{
                  p: "2px 1rem",
                  display: "flex",
                  alignItems: "center",
                  maxWidth: "320px",
                  mb: "2rem",
                  mx: "auto",
                  background: "transparent",
                  borderBottom: "1px solid #fff",
                  borderRadius: 0,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1, color: "#fff" }}
                  placeholder="Search character"
                  inputProps={{ "aria-label": "search google maps" }}
                  value={searchText}
                  onChange={({ target }) => setSearchText(target.value)}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px", color: "#fff" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Box>
            <Box
              component={motion.div}
              variants={containerMotion}
              initial="hidden"
              animate="show"
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "900px",
                mx: "auto",
                overflow: "auto",
                maxHeight: "50vh",
                p: "2rem",
              }}
            >
              {filterList()
                .filter((e) =>
                  valueTab === 0 ? e : e.role === getRole(valueTab)
                )
                .map((character, i) => (
                  <Paper
                    component={motion.div}
                    variants={itemMotion}
                    custom={i}
                    elevation={8}
                    sx={{
                      width: "100%",
                      mb: { xs: "2rem", md: "1rem" },
                      bgcolor: "rgba(0, 0, 0,.3)",
                    }}
                    key={`paper${i}`}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      sx={{ p: { xs: "1rem", md: 0 } }}
                    >
                      <Box
                        display={"flex"}
                        sx={{ flexDirection: { xs: "column", md: "row" } }}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Image
                          fileName={character.image}
                          alt={character.name}
                          csx={{ maxWidth: "100px" }}
                          className="cursor"
                          onClick={() =>
                            openModal({
                              title: character.name,
                              content: character,
                              open: true,
                            })
                          }
                        />
                        <Typography
                          component={"h3"}
                          variant={"h6"}
                          className="cursor"
                          pl={"1rem"}
                          color={"#fff"}
                          sx={{ flexBasis: { xs: "auto", md: "210px" } }}
                          onClick={() =>
                            openModal({
                              title: character.name,
                              content: character,
                              open: true,
                            })
                          }
                        >
                          {character.name}
                        </Typography>
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          sx={{
                            flexGrow: { xs: 0, md: 2 },
                            justifyContent: "center",
                            width: { xs: "100%", md: "auto" },
                            gap: "2rem",
                          }}
                        >
                          {character.perks.length === 3 ? (
                            <Box
                              component="div"
                              width={"81px"}
                              sx={{ display: { xs: "none", md: "flex" } }}
                            ></Box>
                          ) : null}
                          {character.perks.length > 0
                            ? character.perks.map((perk, i) => (
                                <CustomTooltip
                                  key={`tool${perk.id}`}
                                  title={
                                    <Box
                                      component="div"
                                      display="flex"
                                      sx={{ flexDirection: "column" }}
                                      gap=".5rem"
                                    >
                                      <Typography
                                        component={"h3"}
                                        fontWeight="bold"
                                      >
                                        {perk.name}
                                      </Typography>
                                      <Typography
                                        component={"h5"}
                                        variant="body2"
                                      >
                                        {perk.description}
                                      </Typography>
                                      <ColorButton
                                        variant="outlined"
                                        sx={{ ml: "auto", color: "#fff" }}
                                        size="small"
                                        onClick={() =>
                                          matchMd
                                            ? handleDrawer({
                                                content: (
                                                  <PerkDetail
                                                    name={perk.name}
                                                    image={perk.id}
                                                    detail={perk.description}
                                                    width={"320px"}
                                                  />
                                                ),
                                                open: true,
                                                direction: "left",
                                              })
                                            : openModalPerk({
                                                title: perk.name,
                                                content: perk,
                                                open: true,
                                              })
                                        }
                                      >
                                        Ver más
                                      </ColorButton>
                                    </Box>
                                  }
                                >
                                  <Box
                                    component="div"
                                    key={i}
                                    className="cursor"
                                  >
                                    {perk.id}
                                    <Image
                                      fileName={`${perk.id}.png`}
                                      folder={"perks"}
                                      alt={perk.name}
                                      key={`${perk.id}xs`}
                                      csx={{
                                        maxWidth: "80px",
                                        width: "100%",
                                        display: "flex",
                                      }}
                                      onClick={() =>
                                        matchMd
                                          ? // version Desktop
                                            handleDrawer({
                                              content: (
                                                <PerkDetail
                                                  name={perk.name}
                                                  image={perk.id}
                                                  detail={perk.description}
                                                  width={"320px"}
                                                />
                                              ),
                                              open: true,
                                              direction: "left",
                                            })
                                          : // version Mobile
                                            openModalPerk({
                                              title: perk.name,
                                              content: perk,
                                              open: true,
                                            })
                                      }
                                    />
                                  </Box>
                                </CustomTooltip>
                              ))
                            : null}
                        </Box>
                        <Link
                          className="cursor"
                          onClick={() =>
                            openModalBuild({
                              title: "Make a new build",
                              content: character,
                              open: true,
                            })
                          }
                        >
                          <CustomTooltip title="Make a build of this character">
                            <ConstructionIcon
                              sx={{ color: "#fff", fontSize: "2rem" }}
                            />
                          </CustomTooltip>
                        </Link>
                        <Link
                          className="cursor"
                          onClick={() =>
                            openModal({
                              title: character.name,
                              content: character,
                              open: true,
                            })
                          }
                          display={"flex"}
                          sx={{
                            flexBasis: { xs: "auto", md: "100px" },
                            pt: { xs: "1rem", md: 0 },
                          }}
                          justifyContent={"center"}
                        >
                          <CustomTooltip title="See more details">
                            <VisibilityIcon
                              sx={{ color: "#fff", fontSize: "2rem" }}
                            />
                          </CustomTooltip>
                        </Link>
                      </Box>
                    </Box>
                  </Paper>
                ))}
            </Box>
          </>
        )}
      </Box>

      <DialogCustom
        open={detail.open}
        handleClose={() => openModal(false)}
        title={detail.title}
      >
        <CharacterDetailView
          name={detail.content?.name}
          image={detail.content?.id}
          description={detail.content?.description}
          perks={detail.content?.perks}
          role={detail.content?.role}
          slug={detail.content?.slug}
        />
      </DialogCustom>

      <DialogCustom
        open={detailPerk.open}
        handleClose={() => openModalPerk(false)}
        title={detailPerk.title}
      >
        {detailPerk ? (
          <PerkDetail
            name={detailPerk.content?.name}
            image={detailPerk.content?.id}
            detail={detailPerk.content?.description}
          />
        ) : null}
      </DialogCustom>

      <DialogCustom
        open={buildCharacter.open}
        handleClose={() => clearAppBuilds()}
        title={buildCharacter.title}
      >
        {buildCharacter ? (
          <CharacterBuildView character={buildCharacter.content} />
        ) : null}
      </DialogCustom>
      <Sponsor />
    </>
  );
};

export default CharactersView;
