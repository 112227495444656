import { Box, Typography, Button, styled, useMediaQuery, Link, IconButton, Avatar, Divider } from '@mui/material';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomTooltip, Image, PerkDetail, SocialNetwork, Spinner } from '../../components';
import { BuildServices } from '../../services/build.service';
import { grey } from "@mui/material/colors";
import { DrawerContext } from "@contexts/drawer/drawerContext";

const BuildDetail = ({id}) => {
    // const { id } = useParams();
    const [build, setBuild] = useState();
    const matchMd = useMediaQuery("(min-width:900px)");
    const { handleDrawer } = useContext(DrawerContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState({
        name: "",   
        detail: "",
        open: false,
    });

    const openModalPerk = ({ name = "", content = "", open = false }) => {
        setDetail({
          name,
          content,
          open,
        });
    };

    const loadData = async() => {
        setLoading(true);
        const response = (await BuildServices.getDetailBuild(id)).builds[0];
        setBuild(response);
        setLoading(false);
    }

    useEffect(()=>{
        loadData();
    }, [id]);


    const ColorButton = styled(Button)(() => ({
        fontSize: '.65rem',
        color: grey[50],
        borderColor: grey[50],
        "&:hover": {
          borderColor: grey[600],
        },
    }));

    const getTooltipTitle = (perk) => {
        return (
            <Box
                component={"div"}
                display="flex"
                sx={{ flexDirection: "column" }}
                gap=".5rem"
            >
            <Typography component={"h3"} fontWeight='bold'>{perk.name}</Typography>
            <Typography component={"h5"} variant="body2">
              {perk.description}
            </Typography>
            <ColorButton
              variant="outlined"
              sx={{ ml: "auto", color: "#fff" }}
              size="small"
              onClick={() => matchMd ? handleDrawer({
                      content: (
                        <PerkDetail
                          name={perk.name}
                          image={perk.id}
                          detail={perk.description}
                          width={"320px"}
                        />
                      ),
                      open: true,
                      direction: "left",
                    })
                  : openModalPerk({
                      title: perk.name,
                      content: perk,
                      open: true,
                    })
              }
            >
              Ver más
            </ColorButton>
          </Box>
        )
    }


    const getAvatar = () => (
        <Box
            className="box-avatar"
            sx={{
                justifyContent: "center",
                display: "flex",
                mr: '1rem'
            }}
        >
            <CustomTooltip title="Player Name">
                <IconButton sx={{ p: 0 }}>
                    <Avatar alt="avatar" className="avatar">
                        <Image
                            fileName={`icon.png`}
                            folder={"utils"}
                            alt={"logo"}
                            style={{ width: "30px" }}
                        />
                    </Avatar>
                </IconButton>
            </CustomTooltip>
        </Box>
    )

    return ( 

        <Box 
            sx={{ color: 'white', height: '100vh' }}
            display='flex'
            flexDirection='column'
            flexGrow='1'
        > 
            {loading ?
                <Box
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                    mt="5rem"
                    height={'90vh'}
                >
                    <Spinner />
                </Box>
            :
            <>
                { build &&
                    <Box display='flex' flexDirection='row' flexGrow='1' alignItems='center' >
                    <Box flexGrow='1' flexBasis='200px' px='15%'  >
                        <Typography
                            variant="h1"
                            component="h1"
                            color="white"
                            sx={{
                            fontWeight: "bold",
                            typography: { md: "h2", xs: "h3" },
                            textShadow: "5px 5px 5px #000",
                            pb: "1rem",
                            }}
                        >
                            {build.name}
                        </Typography>
                        <Typography
                            variant="body2"
                            component="h5"
                            color="gray"
                            sx={{
                            mt: "1rem",
                            textAlign: "justify",
                            textShadow: "1px 3px 1px #000",
                            typography: { xs: "h6", md: "h5" },
                            }}
                            flexWrap='wrap'
                        >
                            {build.description}
                        </Typography>
                        <Typography
                            variant="body2"
                            component="h5"
                            color="gray"
                            sx={{
                            mt: "1rem",
                            textAlign: "justify",
                            textShadow: "1px 3px 1px #000",
                            typography: { xs: "h6", md: "h5" },
                            }}
                            flexWrap='wrap'
                        >
                            {build.type}
                        </Typography>

                        <Box
                            component="div"
                            py={"2rem"}
                            sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            }}
                        >
                            {build.perks.map((perk, i) => (
                                    <CustomTooltip
                                        key={`Tool${perk.id}`}
                                        title={getTooltipTitle(perk)}
                                    >
                                        <Box component="item-perk" key={i} >
                                        <Image
                                            fileName={perk.image}
                                            className='cursor'
                                            folder={""}
                                            alt={perk.name}
                                            key={`${i}md`}
                                            csx={{
                                            width: { xs: "100px", md: "80px" },
                                            display: "flex",
                                            }}
                                            onClick={() =>
                                            !matchMd
                                                ? // version Desktop
                                                openModalPerk({
                                                    name: perk.name,
                                                    content: perk,
                                                    open: true,
                                                })
                                                : // version Mobile
                                                handleDrawer({
                                                    content: (
                                                    <PerkDetail
                                                        name={perk.name}
                                                        image={perk.id}
                                                        detail={perk.description}
                                                        width={"320px"}
                                                    />
                                                    ),
                                                    open: true,
                                                    direction: "left",
                                                })
                                            }
                                        />
                                        </Box>
                                    </CustomTooltip> 
                            ))}
                        </Box>   
                        
                        <Divider variant="middle" sx={{ m: "1rem 0 0" }} />
                        
                        <Box 
                            sx={{
                                mt: "1rem",
                                textAlign: "justify",
                                textShadow: "1px 3px 1px #000",
                                typography: { xs: "h6", md: "h5" },
                                color: 'white',
                                pb: "1rem",
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >

                            {getAvatar()}
                            <Link
                                
                                component='button'
                                variant='h5'
                                underline='none'
                                // sx={{color: 'white'}}
                                color='white'
                                onClick={()=>navigate('/home')}
                            >
                                { `By ${build.userId.name ?? ''} ${build.userId.lastname ?? '' }` }
                            </Link>
                        </Box>

                        <SocialNetwork 
                            youtube={build.userId.youtube}
                            facebook={build.userId.facebook}
                            twitch={build.userId.twitch}
                            twitter={build.userId.twitter}
                            instagram={build.userId.instagram}
                        />       
                    </Box>
                    <Box flexGrow='1' justifyContent='center'>
                        {/* <img 
                            alt='' 
                            src={require(`../../../assets/characters/complete/${build.character.slug}.png`)}
                        /> */}
                            <Image
                                fileName={`${build.character.slug}.png`}
                                folder={"characters/complete"}
                                alt={build.character.name}
                                csx={{ width: "100%", maxWidth: { xs: "100px", md: "220px" } }}
                            />
                    </Box>

                </Box>

                } 
            </>        


            }

        </Box>


    );
}
 
export default BuildDetail;