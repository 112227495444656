import React, { useContext } from 'react';
import Drawer from '@mui/material/Drawer';
import { DrawerContext } from '@contexts/drawer/drawerContext';
import { AppContext } from '../../application/Provider';

const DrawerMenu = () => {
    const [state, setState] = useContext(AppContext);
    const { drawerContent, handleDrawer } = useContext(DrawerContext);

    const toggleDrawer = (direction, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        handleDrawer({ open: open, direction: direction, content: drawerContent.content });
        eventDrawer(open);
    };

    const eventDrawer = (open) => {
        setState({
          ...state,
          drawer: {
            open: open
          }
        });
      };

    return (
        <Drawer sx={{zIndex: '999999'}}
            anchor={drawerContent.direction}
            open={drawerContent.open}
            onClose={toggleDrawer(drawerContent.direction, false)}
        >
            {drawerContent.content}
        </Drawer>
    );
}

export default DrawerMenu;