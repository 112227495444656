import { Avatar, Box, Divider, IconButton, InputBase, Link, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
    Search as SearchIcon,
    FilterAlt as FilterAltIcon,
    Share as ShareIcon,
    FavoriteBorderOutlined as FavoriteBorderOutlinedIcon,
} from "@mui/icons-material";
import { Image, Spinner } from "@components";
import { DialogCustom } from '../../components';
import BuildDetail from './BuildDetail';
import { motion } from "framer-motion";


const BuildList = ({ filterRole, builds, title, searchHandler, loading, type }) => {
    const [searchText, setSearchText] = useState('');
    const [role, setRole] = useState({
        killer: true,
        survivor: true,
    });

    const [buildModal, setBuildModal] = useState({
        open: false,
        title: '',
        buildId: '',
    }) 

    const search = (e) => {
        if(e.keyCode === 13){
            searchHandler(searchText);
        }
    }

    const handleSurvivorFilter = (e) => {
        const selectedRoles = {
            ...role,
            survivor: !role.survivor,
        }
        setRole(selectedRoles);
        filterRole(getQuery(selectedRoles), type);
    }

    const getQuery = (selectedRoles) => {
        const roles = [];

        if(selectedRoles.survivor) {
            roles.push('survivor');
        }  
        if(selectedRoles.killer) {
            roles.push('killer');
        }
        if(roles.length === 2){
            return undefined;
        } else if (roles.length === 0) {
            return '';
        } else {
            return roles[0];
        }
    }

    const containerMotion = {
        hidden: { opacity: 0 },
        show: {
          opacity: 1,
          transition: {
            staggerChildren: 0.1,
          },
        },
    };

    const handleKillerFilter = (e) => {
        const selectedRoles = {
            ...role,
            killer: !role.killer,
        }
        setRole(selectedRoles);
        filterRole(getQuery(selectedRoles), type);
    }

    const closeModal = () => {
        setBuildModal({
            ...buildModal,
            open: false,
        });
    };

    const openModal = (id) => {
        console.log('openModal ', id)
        setBuildModal({
            ...buildModal,
            open: true,
            buildId: id,
        });
    };


    const buildTableList = () => (
        <Box display='flex' flexDirection='column' >
            <Typography component='h3' variant='h5' gutterBottom textAlign='center'> 
                {title}
            </Typography>
            {/* <Divider variant='middle' sx={{ m: "1rem 0 0" }} /> */}
            <Box
                display='flex'
                sx={{ flexDirection: { xs: "column", md: "row" } }}
                mb='1.5rem'
            >

                <Paper
                    sx={{
                        p: "2px 1rem",
                        display: "flex",
                        alignItems: "center",
                        maxWidth: "400px",
                        mx: "auto",
                        background: "transparent",
                        borderBottom: "1px solid #fff",
                        borderRadius: 0,
                    }}
                    >
                    <InputBase
                        sx={{ ml: 1, flex: 1, color: "#fff" }}
                        placeholder="Search"
                        // inputProps={{ "aria-label": "search google maps" }}
                        onChange={(e) => setSearchText(e.target.value) }
                        onKeyDown={search}
                        value={searchText}
                    />
                    {/* <TextField
                        sx={{ ml: 1, flex: 1, input: { color: '#fff'} }}
                        placeholder="Search"
                        //  inputProps={{ color: '"#fff"' }}
                        onChange={(e) => setSearchText(e.target.value) }
                        // onChange={(e) => searchHandler(e.target.value) }
                        onKeyDown={search}
                        value={searchText}
                    /> */}
                    <IconButton
                        type="button"
                        sx={{ p: "10px", color: "#fff" }}
                        aria-label="search"
                    >
                        <SearchIcon />
                    </IconButton>
                </Paper>

                <ToggleButtonGroup
                    value={filterRole}
                    // onChange={handleFormat}
                    aria-label="filter by role"
                    sx={{ maxHeight: "35px", padding: '10px' }}
                >
                <ToggleButton sx={{ borderColor: "#fff" }} value="">
                    <FilterAltIcon sx={{ color: "#fff" }} />
                    <Typography
                        component={"h6"}
                        variant={"body2"}
                        color={"#fff"}
                        sx={{ textTransform: "capitalize" }}
                    >
                        Filter
                    </Typography>
                </ToggleButton>
                <ToggleButton
                    value="survivor"
                    aria-label="survivor"
                    sx={{ borderColor: "#fff" }}
                    onClick={handleSurvivorFilter}
                >
                    <Image
                        fileName={`survivor_logo.svg`}
                        folder={"utils"}
                        alt={"survivor"}
                        csx={{
                            width: "25px",
                            // opacity: filterRole.includes("survivor") ? "1" : ".3",
                            opacity:  role.survivor ? '1' : '.3',
                            transition: "all ease .3s",
                        }}
                    />
                </ToggleButton>
                <ToggleButton
                    value="killer"
                    aria-label="killer"
                    sx={{ borderColor: "#fff" }}
                    onClick={handleKillerFilter}
                >
                    <Image
                        fileName={`killer_logo.svg`}
                        folder={"utils"}
                        alt={"survivor"}
                        csx={{
                            width: "25px",
                            // opacity: filterRole.includes("killer") ? "1" : ".3",
                            opacity:  role.killer ? '1' : '.3',
                            transition: "all ease .3s",
                        }}
                    />
                </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            { !loading > 0 ?
                <Box display='flex' flexDirection='column'>
                    <TableContainer
                            sx={{ 
                            background: "transparent",  
                            maxHeight:'auto', 
                            overflow:'auto',
                            padding: '24px'
                            }}
                            elevation={0}
                        >
                            <Table sx={{ width: "100%", }} aria-label="simple table">
                                <TableBody>
                                    {builds.map(
                                    ({ name, _id, character, likes, perks, type }) => (
                                        <TableRow
                                            key={_id}
                                            sx={{
                                                "&:last-child td, &:last-child th": {
                                                border: 0,
                                                },
                                            }}
                                        >
                                        <TableCell>
                                            <Avatar
                                                alt={name}
                                                src={character.image}
                                                className="avatar"
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Link
                                                sx={{color: 'white', cursor: 'pointer'}}
                                                onClick={() => {
                                                    openModal(_id)
                                                }}
                                            >
                                                {name}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="center">{type}</TableCell>
                                        <TableCell align="center">
                                            <Box
                                                display={"flex"}
                                                flexDirection={"row"}
                                                justifyContent={"center"}
                                                gap={"1rem"}
                                            >
                                            {perks && perks.length > 0
                                                ? perks.map(({ id, name }, i) => (
                                                    <Tooltip key={`tool${id}`} title={name}>
                                                    <Box component="div" key={i}>
                                                        {/* version Desktop */}
                                                        <Image
                                                            fileName={`${id}.png`}
                                                            folder={"perks"}
                                                            alt={name}
                                                            key={`${id}md`}
                                                            csx={{
                                                                maxWidth: "80px",
                                                                width: "100%",
                                                                display: {
                                                                xs: "flex",
                                                                md: "none",
                                                                },
                                                            }}
                                                        />
                                                        {/* version Mobile */}
                                                        <Image
                                                            fileName={`${id}.png`}
                                                            folder={"perks"}
                                                            alt={name}
                                                            key={`${id}xs`}
                                                            csx={{
                                                                maxWidth: "80px",
                                                                width: "100%",
                                                                display: {
                                                                xs: "none",
                                                                md: "flex",
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                    </Tooltip>
                                                ))
                                                : null}
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center">
                                            {likes}
                                        </TableCell>
                                        <TableCell align="center">
                                            <ShareIcon fontSize="small" />
                                        </TableCell>
                                        <TableCell align="center">
                                            <FavoriteBorderOutlinedIcon fontSize="small" />
                                        </TableCell>
                                        </TableRow>
                                    )
                                    )}
                                </TableBody>
                            </Table>
                    </TableContainer>
                </Box>
            :
                <Box
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                    mt="5rem"
                >
                    <Spinner />
                </Box>
            }
        </Box>

    )


    const buildResponsiveTable = () => (
        <>
            <Box
              component={motion.div}
              variants={containerMotion}
              initial='hidden'
              animate='show'
              display='flex'
              flexDirection='column'
              sx={{
                maxWidth: "900px",
                mx: "auto",
                overflow: "auto",
                maxHeight: "50vh",
                p: "2rem",
              }}
            >

                
            </Box>
        
        </>
    );

    return  (
        <>    
            
            {buildTableList()}
            <DialogCustom
                open={buildModal.open}
                handleClose={() => closeModal()}
                title={buildModal.title}
            >
                <BuildDetail
                    id={buildModal.buildId}
                />    
            </DialogCustom>
        </>
    );
}
 
export default BuildList;