import axios from '../config/axios';

export const CharacterServices = (() => {

    const getCharacters = async () => {
        try {
            const charactersResponse = await axios.get('characters');
            return charactersResponse.data;
        } catch (err) {
            console.log(err);
        }
    }

    const getCharacterDetail = async (id) => {
        try {
            const characterDetailResponse = await axios.get(`characters/${id}`);
            return characterDetailResponse.data;
        } catch (err) {
            console.log(err);
        }
    }

    return {
        getCharacters,
        getCharacterDetail
    };

})();