import { Typography } from '@mui/material';
import React from 'react';

const ErrorMessage = ({ name, value, type, color = 'red' }) => {

  React.useEffect(() => {
    // console.log(type, value);
  }, [type])
  return (
    <>
      <Typography component='h5' variant='body2' color={color}>
        {type === 'required' && !name
          ? 'requerido'
          : type === 'required' && name?
          `el campo ${name} es requerido`
          : type === 'maxLength'
          ? `max. ${value} caracteres`
          : type === 'minLength'
          ? `min. ${value} caracteres`
          : null}
      </Typography>
    </>
  );
};

export default ErrorMessage;
