import React, { useContext, useState } from "react";
import { Box, Button, FormControl, Typography } from "@mui/material";
import {
  Image,
  InputCustom,
  SelectCustom,
  DialogCustom,
  CustomTooltip,
} from "@components";
import { FormCustom } from "@hooks";
import { AppContext } from "@application";
import { BuildServices } from "@services";
import { useNavigate } from "react-router-dom";
import PerkSelector from "./PerkSelector";
import "./CharacterbuildView.scss";

const CharacterBuildView = ({ character }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useContext(AppContext);
  const [openWarning, setOpenWarning] = useState(false);
  const [role, setRole] = useState("");

  const optionsTypeBuild = [
    { text: "Solo Q", value: "Solo Q" },
    { text: "Genrush", value: "Genrush" },
    { text: "Loop", value: "Loop" },
  ];

  const onSubmit = async (data) => {
    const slots = state.build.selected;
    if (slots.filter((e) => Object.entries(e).length === 0).length > 0) {
      setOpenWarning({
        open: true,
        text: "You must select all perks",
      });
      return;
    }

    const form = {
      userId: "631d4a1568ee8d09be606cc9",
      character: character._id,
      role: character.role,
      ...data,
      perks: slots.map((e) => e._id),
    };

    await sendData(form);
  };

  const sendData = async (form) => {
    setLoading(true);
    await BuildServices.createBuild(form);
    setLoading(false);
    navigate("/builds");
  };

  const openModal = (open, index, role) => {
    setRole(role);
    setState({
      ...state,
      modal: {
        open: open,
        name: open ? "Select perk" : "",
      },
      build: {
        ...state.build,
        slotIndex: index,
      },
    });
  };

  const isEmptySlot = (index) => {
    return JSON.stringify(state.build.selected[index]) === "{}";
  };

  return (
    <>
      {character ? (
        <FormCustom onSubmit={onSubmit}>
          <Box
            component="div"
            pt={"3rem"}
            sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
            className="box-content"
            alignItems={"center"}
          >
            <Box
              component="div"
              sx={{
                display: "flex",
                flexBasis: { md: "50%", lg: "35%" },
                order: { xs: 1, md: 0 },
                flexGrow: { xs: 0, md: 1 },
                p: { xs: "1rem", md: "4rem" },
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h1"
                component="h1"
                color="white"
                sx={{
                  fontWeight: "bold",
                  typography: { md: "h2", xs: "h3" },
                  textShadow: "5px 5px 5px #000",
                  pb: "2rem",
                }}
              >
                {character.name}
              </Typography>

              <Box
                component="div"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {state.build.selected.map((perkSelected, i) => (
                  <CustomTooltip
                    key={`tool${i}`}
                    title={
                      isEmptySlot(i) ? (
                        "Empty slot"
                      ) : (
                        <Box
                          component="div"
                          display="flex"
                          sx={{ flexDirection: "column" }}
                          gap=".5rem"
                        >
                          <Typography component={"h3"} fontWeight="bold">
                            {perkSelected.name}
                          </Typography>
                          <Typography component={"h5"} variant="body2">
                            {perkSelected.description}
                          </Typography>
                        </Box>
                      )
                    }
                  >
                    <Box component="item-perk" key={i}>
                      <Image
                        fileName={
                          isEmptySlot(i)
                            ? `slot1.png`
                            : `${perkSelected.id}.png`
                        }
                        folder={"perks"}
                        alt={perkSelected.name}
                        key={`${i}xs`}
                        csx={{ width: { xs: "60px", md: "100px" } }}
                        className="cursor"
                        onClick={() => openModal(true, i, character.role)}
                      />
                    </Box>
                  </CustomTooltip>
                ))}
              </Box>

              <Box
                component="div"
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <InputCustom
                  name="name"
                  label="Build name"
                  fullWidth
                  variant="standard"
                  InputProps={{
                    inputProps: {
                      style: { color: "#fff", borderBottom: "1px solid #555" },
                    },
                  }}
                  csx={{ mb: "1rem", label: { color: "#555" } }}
                  rules={{
                    required: { value: true },
                    maxLength: { value: 255 },
                  }}
                />

                <FormControl
                  variant="standard"
                  sx={{ background: "transparent", mb: "1rem" }}
                >
                  <SelectCustom
                    SelectDisplayProps={{
                      style: {
                        paddingLeft: 0,
                        borderBottom: "1px solid #555",
                        color: "#fff",
                      },
                    }}
                    name="type"
                    labelId="buildType"
                    label="Build type"
                    options={optionsTypeBuild}
                    icsx={{ color: "#555" }}
                    csx={{
                      background: "transparent",
                      color: "#fff",
                      paddingLeft: 0,
                      ".MuiSelect-icon": { color: "white" },
                    }}
                    rules={{
                      required: { value: true },
                    }}
                  ></SelectCustom>
                </FormControl>
                <InputCustom
                  name="description"
                  label="Description"
                  multiline
                  variant="standard"
                  InputProps={{ inputProps: { style: { color: "#fff" } } }}
                  className="textField"
                  sx={{ background: "transparent", label: { color: "#555" } }}
                  rules={{
                    maxLength: { value: 255 },
                  }}
                />
              </Box>
              <Box
                component="div"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "#211a31",
                    color: "#fff",
                    my: "2rem",
                    px: "3rem",
                    fontWeight: "bold",
                  }}
                >
                  Enviar
                </Button>
              </Box>
            </Box>
            <Box
              component="div"
              className={`box-character ${character.role}`}
              sx={{
                order: { xs: 0, md: 1 },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexBasis: { md: "50%", lg: "65%" },
              }}
            >
              <Image
                fileName={`${character.slug}.png`}
                folder={"characters/complete"}
                alt={character.name}
                csx={{ width: "100%", maxWidth: { xs: "100px", md: "220px" } }}
              />
            </Box>
          </Box>
        </FormCustom>
      ) : null}

      <DialogCustom
        title="Select perk"
        open={state.modal.open}
        handleClose={() => openModal(false)}
      >
        <PerkSelector role={role} />
      </DialogCustom>

      <DialogCustom
        title=""
        open={openWarning.open}
        fullScreen={false}
        handleClose={() => setOpenWarning(false)}
        direction="up"
      >
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ height: "200px", width: "600px", p: "1rem" }}
        >
          <Typography component="h4" variant="h5" color="#fff">
            {openWarning.text}
          </Typography>
        </Box>
      </DialogCustom>
    </>
  );
};

export default CharacterBuildView;
