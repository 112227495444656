import "./App.scss";
import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { Box, Snackbar } from "@mui/material";
import { Navbar, Footer } from "@components";
import DrawerProvider from "@contexts/drawer/drawerContext";
import {
  Home,
  About,
  CharacterDetailView,
  BuildsView,
  CharactersView,
  BuildViews,
  Login,
  PerksView,
  BuildDetail,
} from "@pages";
import Provider from "./application/Provider";

function App() {
  return (
    <div>
      <Provider>
        <DrawerProvider>
          <Navbar />
          <Box
            display="flex"
            flexDirection="column"
            sx={{ pt: { xs: "0rem", md: "5rem" } }}
          >
            <Routes>
              <Route path="/" element={<Navigate to="/home" />} />
              <Route path="/home" element={<Home />} />
              <Route path="/characters" element={<CharactersView />} />
              <Route path="/characters/:id" element={<CharactersView />} />
              <Route path="/builds" element={<BuildViews />} />
              <Route path="/builds/:id" element={<BuildDetail/>} />
              <Route path="/build/:id" element={<CharacterDetailView />} />
              <Route path="/about" element={<About />} />
              <Route path="/login" element={<Login />} />
              <Route path="/perks" element={<PerksView />} />
              <Route path="/perks/:id" element={<PerksView />} />
            </Routes>
            <Footer />
            <Snackbar />
          </Box>
        </DrawerProvider>
      </Provider>
    </div>
  );
}

export default App;
