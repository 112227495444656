import React, { createContext, useState } from "react";

const Provider = (props) => {
  const [state, setState] = useState({
    modal: {
      open: false,
      fullScreen: false,
      title: "",
    },
    build: {
      slotIndex: 0,
      selected: new Array(4).fill({}),
    },
    drawer: {
      open: false,
      content: null,
      direction: "",
    },
    characters: [],
    perks: [],
  });

  return (
    <AppContext.Provider value={[state, setState]}>
      {props.children}
    </AppContext.Provider>
  );
};

export default Provider;
export const AppContext = createContext();
