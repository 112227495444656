import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, useMediaQuery, Button } from "@mui/material";
import Image from "../images/Image";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {
  Share as ShareIcon,
  Favorite as FavoriteIcon,
} from "@mui/icons-material";
import { CustomTooltip, PerkDetail, DialogCustom } from "@components";
import { CharacterDetailView } from "@pages";
import { DrawerContext } from "@contexts/drawer/drawerContext";
import PropTypes from "prop-types";
import "./card.scss";
import { styled } from "@mui/system";
import { grey } from "@mui/material/colors";
import { motion } from "framer-motion";

const ColorButton = styled(Button)(() => ({
  fontSize: ".65rem",
  color: grey[50],
  borderColor: grey[50],
  "&:hover": {
    borderColor: grey[600],
  },
}));

const CardComponent = ({
  builds,
  image,
  name,
  perks,
  type,
  csx,
  likes,
  typeBuild,
  character,
  children,
}) => {
  const matchMd = useMediaQuery("(min-width:900px)");
  const { handleDrawer } = React.useContext(DrawerContext);
  const defaultContent = {
    name: "",
    id: "",
    description: "",
  };
  const [detailPerk, setDetailPerk] = React.useState({
    title: "",
    content: defaultContent,
    open: false,
  });

  const [detail, setDetail] = React.useState({
    title: "",
    content: defaultContent,
    open: false,
  });

  const openModalPerk = ({
    title = "",
    content = defaultContent,
    open = false,
  }) =>
    setDetailPerk({
      title,
      content,
      open,
    });

  const openModal = ({ title = "", content = defaultContent, open = false }) =>
    setDetail({
      title,
      content,
      open,
    });

  return (
    <Card className={`box-card ${type}`} sx={csx}>
      <CardContent p={"1rem"}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          {!children ? (
            <Box flexGrow="1">
              <Box sx={{ textAlign: "left" }}>
                <Typography
                  component={"h2"}
                  fontWeight="bold"
                  sx={{
                    typography: { xs: "h5", sm: "h6", lg: "h5" },
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "160px",
                    textOverflow: "ellipsis",
                  }}
                >
                  {name}
                </Typography>
                <Typography component={"h6"} variant={"body2"}>
                  {builds} Builds
                </Typography>
              </Box>
              <Box className="box-perks">
                {perks?.length > 0
                  ? perks.map((perk) => (
                      <Box
                        component="div"
                        className="item-perk"
                        key={`box${perk.id}`}
                      >
                        <CustomTooltip
                          key={`Tool${perk.id}`}
                          title={
                            <Box
                              component={"div"}
                              display="flex"
                              sx={{ flexDirection: "column" }}
                              gap=".5rem"
                            >
                              <Typography component={"h3"} fontWeight="bold">
                                {perk.name}
                              </Typography>
                              <Typography component={"h5"} variant="body2">
                                {perk.description}
                              </Typography>
                              <ColorButton
                                variant="outlined"
                                sx={{ ml: "auto", color: "#fff" }}
                                size="small"
                                onClick={() =>
                                  matchMd
                                    ? handleDrawer({
                                        content: (
                                          <PerkDetail
                                            name={perk.name}
                                            image={perk.id}
                                            detail={perk.description}
                                            width={"320px"}
                                          />
                                        ),
                                        open: true,
                                        direction: "left",
                                      })
                                    : openModalPerk({
                                        title: perk.name,
                                        content: perk,
                                        open: true,
                                      })
                                }
                              >
                                Ver más
                              </ColorButton>
                            </Box>
                          }
                        >
                          <Box component={"div"} className={"cursor"}>
                            <Image
                              fileName={`${perk.id}.png`}
                              folder={"perks"}
                              alt={perk.name}
                              key={perk.id}
                              style={{ maxWidth: "60px", width: "100%" }}
                              onClick={() =>
                                matchMd
                                  ? handleDrawer({
                                      content: (
                                        <PerkDetail
                                          name={perk.name}
                                          image={perk.id}
                                          detail={perk.description}
                                          width={"320px"}
                                        />
                                      ),
                                      open: true,
                                      direction: "left",
                                    })
                                  : openModalPerk({
                                      title: perk.name,
                                      content: perk,
                                      open: true,
                                    })
                              }
                            />
                          </Box>
                        </CustomTooltip>
                      </Box>
                    ))
                  : null}
              </Box>
            </Box>
          ) : (
            <Box flexGrow="1">
              {children}
              <Box sx={{ textAlign: "left" }}>
                <Typography
                  component={"h2"}
                  fontWeight="bold"
                  sx={{
                    typography: { xs: "h5", sm: "h6", lg: "h5" },
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "160px",
                    textOverflow: "ellipsis",
                  }}
                >
                  {typeBuild}
                </Typography>
              </Box>
              <Box className="box-perks">
                {perks?.length > 0
                  ? perks.map(({ id, name }) => (
                      <Box
                        component="div"
                        className="item-perk"
                        key={`box${id}`}
                      >
                        <Image
                          fileName={`${id}.png`}
                          folder={"perks"}
                          alt={name}
                          key={id}
                          style={{ maxWidth: "60px", width: "100%" }}
                        />
                      </Box>
                    ))
                  : null}
                {perks?.length > 0
                  ? [perks[0]].map(({ id, name, image }) => (
                      <Box
                        component="div"
                        className="item-perk"
                        key={`box${id}`}
                      >
                        <Image
                          fileName={`${id}.png`}
                          folder={"perks"}
                          alt={name}
                          key={id}
                          style={{ maxWidth: "60px", width: "100%" }}
                        />
                      </Box>
                    ))
                  : null}
              </Box>
            </Box>
          )}
          <Box className="box-character" component="div">
            <Image
              fileName={`${image}.png`}
              folder={"characters/complete"}
              alt={name}
              style={{ maxWidth: "60px", width: "100%" }}
              onClick={() =>
                openModal({
                  title: character.name,
                  content: character,
                  open: true,
                })
              }
            />
          </Box>
        </Box>
        {likes ? (
          <Box
            component={"div"}
            display={"flex"}
            justifyContent={"end"}
            alignItems={"center"}
            className={"box-icons"}
          >
            <ThumbUpIcon fontSize="small" />
            <Typography
              component={"h6"}
              variant={"body2"}
              ml={".5rem"}
              color={"#fff"}
            >
              {likes}
            </Typography>
            <ShareIcon fontSize="small" sx={{ mx: ".5rem" }} />
            <FavoriteIcon fontSize="small" sx={{ color: "red" }} />
          </Box>
        ) : null}
      </CardContent>

      <DialogCustom
        open={detailPerk.open}
        handleClose={() => openModalPerk(false)}
        title={detailPerk.title}
      >
        {detailPerk ? (
          <PerkDetail
            name={detailPerk.content?.name}
            image={detailPerk.content?.id}
            detail={detailPerk.content?.description}
          />
        ) : null}
      </DialogCustom>

      <DialogCustom
        open={detail.open}
        handleClose={() => openModal(false)}
        title={detail.title}
      >
        <CharacterDetailView
          name={detail.content?.name}
          image={detail.content?.id}
          description={detail.content?.description}
          perks={detail.content?.perks}
          role={detail.content?.role}
          slug={detail.content?.slug}
        />
      </DialogCustom>
    </Card>
  );
};

CardComponent.propTypes = {
  builds: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default CardComponent;
