import axios from '../config/axios';

export const BuildServices = (() => {
  const getBuilds = async (query) => {
    try {
      const buildResponse = await axios.get(
        'builds',
        {
          params: query,
        }
      );
      return buildResponse.data;
    } catch (err) {
      console.log(err);
    }
  };


  const getDetailBuild = async(id) => {
    const build = await axios.get(`builds/${id}`);
    return build.data;
  }

  const getTopBuilds = async (query) => {
    try {
      const buildResponse = await axios.get(
        'builds/top',
        {
          params: {
            ...query,
            page: 1,
            pageSize: 10,
          },
        }
      );
      return buildResponse.data;
    } catch (err) {
      console.log(err);
    }
  };

  const createBuild = async (build) => {
    try {
      const createBuildResponse = await axios.post(
        `builds`,
        build
      );
      return createBuildResponse.data;
    } catch (err) {
      console.log(err);
    }
  };

  return {
    getBuilds,
    getTopBuilds,
    createBuild,
    getDetailBuild,
  };
})();
