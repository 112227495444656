import { styled, Tooltip, tooltipClasses } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#211a31",
    color: "#fff",
    maxWidth: 320,
    padding: ".5rem",
    fontSize: theme.typography.pxToRem(12),
  },
}));

const CustomTooltip = ({ children, title }) => {
  return <HtmlTooltip title={title}>{children}</HtmlTooltip>;
};

CustomTooltip.propTypes = {
    title: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired
  };

export default CustomTooltip;
