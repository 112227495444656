import React from 'react';
import PropTypes from "prop-types";
import { styled } from '@mui/material/styles';

const TwitchIcon = (props) => {
  const { size, color, ...other } = props;

  
  const StyledSvg = styled('svg')({
    // animation: `${rotationEffect} 2s linear infinite`,
  });

  return ( 
    <StyledSvg
      width={size} 
      height={size} 
      viewBox="0 0 20 21" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg" 
      {...other}
    >
      <title>twitch-icon</title>
      <defs />
      <g stroke="none" fill="none" strokeWidth="1" fillRule="evenodd">
        <g fill={color}>
            <path d="M11.64 5.93H13.07V10.21H11.64M15.57 5.93H17V10.21H15.57M7 2L3.43 5.57V18.43H7.71V22L11.29 18.43H14.14L20.57 12V2M19.14 11.29L16.29 14.14H13.43L10.93 16.64V14.14H7.71V3.43H19.14Z" />
        </g>
      </g>
    </StyledSvg>
   );
}

TwitchIcon.defaultProps = {
  color: "white",
  size: 24
};

TwitchIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
 
export default TwitchIcon;
