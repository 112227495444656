import axios from '../config/axios';

export const PerkServices = (() => {

    const getPerks = async() => {
        // try {
            const perksResponse = await axios.get('perks');
            return perksResponse.data;

        // }catch(err){
        //     console.log(err);
        // }
    }

    return {
        getPerks
    };

})();