import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { ErrorMessage } from "@components";

const InputCustom = ({ label, name, rules, csx, ...rest }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <TextField
        sx={csx}
        label={label}
        {...register(name, { ...rules })}
        {...rest}
      />
{/* 
      {errors[name] && errors[name].type === "positiveNumber" && (
        <p style={{color:"red"}}>Your age is invalid</p>
      )} */}


      {rules
        ? Object.keys(rules).map((error, i) => {
            return (
              errors &&
              errors?.[name]?.type === error && (
                <ErrorMessage
                  name={rules[error].message ? label : ""}
                  value={rules[error].value}
                  type={error}
                  key={i}
                />
              )
            );
          })
        : null}
    </>
  );
};

export default InputCustom;
