import { Box, IconButton, Link } from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitchIcon from "../twitchIcon/TwitchIcon";



const SocialNetwork = ({
    youtube,
    facebook,
    twitch,
    twitter,
    instagram,
}) => {

    const getIcon = (network) => {
        
        const networkIcons = {
            youtube: <YouTubeIcon/>,
            facebook: <FacebookIcon/>,
            twitch: <TwitchIcon/> ,
            twitter: <TwitterIcon/>,
            instagram: <InstagramIcon/>,
        }
        return networkIcons[network];
    }

    const userNetworks = [ 
        {url: youtube, name: 'youtube' },
        {url: facebook, name: 'facebook' },
        {url: twitch, name: 'twitch' },
        {url: twitter, name: 'twitter' },
        {url: instagram, name: 'instagram' },
    ]

    return ( 
        <Box display='flex' flexDirection='row'>

           {
            userNetworks.map( network => (
                <IconButton
                    type="button"
                    sx={{ p: "10px", color: "#fff" }}
                    aria-label={network.name}
                    key={network.name}
                >
                    <Link 
                        href={network.url} 
                        target="_blank" alt='youtube' 
                        rel="noreferrer"
                        sx={{ color: 'white'}}
                    >
                        {getIcon(network.name)}
                    </Link>
                </IconButton>
            ))
           } 

        </Box>
    );
}
 
export default SocialNetwork;