import React from "react";
import PrincipalSection from "./principalSection/PrincipalSection";
import CharacterSection from "./charactersSection/CharactersSection";
import { Sponsor } from "@components";
import "./home.scss";
import "swiper/css";
import { Box } from "@mui/material";

const Home = () => {
  return (
    <>
      <Box sx={{ pb: "3rem", mt: { xs: "0", md: "-5rem" } }}>
        {/* Home section */}
        <PrincipalSection />
        {/* Character section */}
        <CharacterSection />
        {/* Sponsor section */}
        <Sponsor />
      </Box>
    </>
  );
};

export default Home;
