import { Box } from '@mui/system';
import React from 'react';
import './Spinner.scss';
import PropTypes from "prop-types";


const Spinner = ({width, height }) => {
    return ( <>
    <Box className="lds-dual-ring" sx={{width, height}} ></Box>
    </> );
}

Spinner.defaultProps = {
    width: '64px',
    height: '64px',
}

Spinner.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
};


export default Spinner;