import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Image, LoginCarousel } from "@components";
import { styled } from "@mui/system";
import { grey } from "@mui/material/colors";

const ColorButton = styled(Button)(() => ({
  color: grey[50],
  borderColor: grey[50],
  height: "50px",
  justifyContent: "flex-start",
  "&:hover": {
    borderColor: grey[600],
  },
}));

const Login = () => {
  return (
    <>
      <Box
        display="flex"
        sx={{
          flexDirection: { xs: "column", lg: "row" },
          px: { md: "0rem", lg: "4rem" },
          gap: "2rem",
          pb:'5rem',
        }}
      >
        <Box
          display="flex"
          sx={{
            justifyContent: "center",
            background: "#211a31",
            borderRadius: "8px",
            p: "2rem",
            flexDirection: "column",
            color: "#fff",
            minHeight: "600px",
            mx:'auto',
            maxWidth: {xs: 'none', sm: '320px', lg: 'none'},
            flexBasis: {xs: 'auto', lg: "22%"},
          }}
        >
          <Image
            fileName="logo.png"
            folder="utils"
            alt="logo"
            csx={{ width: "80px", mx: "auto" }}
          />
          <Typography component="h1" variant="body1" my="1rem">
            Create your Behaviour account to link your game progress across PC &
            console.
          </Typography>
          <Box display="flex" flexDirection="column" gap="1rem">
            <ColorButton
              variant="outlined"
              startIcon={
                <Image
                  fileName="twitch_logo.png"
                  folder="utils"
                  alt="logo twitch"
                  csx={{ width: "40px" }}
                />
              }
            >
              Continue with Twitch
            </ColorButton>
            <ColorButton
              variant="outlined"
              startIcon={
                <Image
                  fileName="facebook_logo.png"
                  folder="utils"
                  alt="logo facebook"
                  csx={{ width: "40px" }}
                />
              }
            >
              Continue with Facebook
            </ColorButton>
            <ColorButton
              variant="outlined"
              startIcon={
                <Image
                  fileName="google_logo.png"
                  folder="utils"
                  alt="logo google"
                  csx={{ width: "40px" }}
                />
              }
            >
              Continue with Google
            </ColorButton>
          </Box>
        </Box>

        <Box
            component="div"
            display='flex'
            minHeight='600px'
            sx={{
              maxWidth: { xs: "100%", lg: "71%" },
              flexBasis: {xs: 'auto', lg: "78%"},
            }}
        >
        <LoginCarousel />
        </Box>
      </Box>
    </>
  );
};

export default Login;
