import React from 'react';
import { Box } from '@mui/material';
import CardComponent from '../../components/card/Card';

const About = () => {
    return (
        <>
            <Box component="div" sx={{ flex: '1 1 auto !important', p: '5rem', margin: '0 auto' }}>
                <CardComponent
                    name={`Meg Thomas`}
                    builds={30}
                    image={'https://preview.redd.it/ph9ur9vw8f931.png?width=640&crop=smart&auto=webp&s=3a005bac81e778cbc71a61c7f1abfeac924927a1'}
                    module={'details'} />
            </Box>
        </>
    );
}

export default About;