import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Image } from '@components';
import './perkDetail.scss';

const PerkDetail = ({ name, image, detail, width }) => {
    return (
        <>
            <Box component='div' className='box-perk-detail' display={'flex'} sx={{width: width}}
            flexDirection={'column'}>
                <Box component='div' className='box-img-perk' p={'2rem'}>
                    <Image fileName={`${image}.png`} folder={'perks'} alt={name} 
                    sx={{width: '100%', maxWidth: '130px'}}/>
                </Box>
                <Box component='div' p={'2rem'} className='box-perk-text'>
                    <Typography component='h2' variant='h4' sx={{color: 'white', width: '100%'}}>
                        {name}
                    </Typography>

                    <Typography component='h5' sx={{color: 'white', width: '100%'}}>
                        {detail}
                    </Typography>
                </Box>
            </Box>
        </>
    );
}

PerkDetail.propTypes = {
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    detail: PropTypes.string,
    width: PropTypes.string,
}
export default PerkDetail;