import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormProvider } from "react-hook-form";

const useFormCustom = ({ defaultValues, children, onSubmit }) => {
  const methods = useForm({ defaultValues,  mode: "onBlur" });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>{children}</Box>
    </FormProvider>
  );
};

export default useFormCustom;
