import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { Image } from "@components";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "./carousel.scss";

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const delay = 1.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};


const LoginCarousel = () => {
const [activeSlide, setActiveSlide] = useState(0);
  const pagination = {
    clickable: true,
  };

  const itemsCarousel = [
    {
      text: (
        <>
          Make your own <b>builds </b>
          and <b>share them</b>
        </>
      ),
      characterImage: "meg-thomas.png",
      backImage: "cazadora.png",
      firstMiniImage: "build1.png",
      secondMiniImage: "build1.png",
    },
    {
      text: (
        <>
          Make your own <b>builds </b>
          and <b>share them 2</b>
        </>
      ),
      characterImage: "meg-thomas.png",
      backImage: "cazadora.png",
      firstMiniImage: "build1.png",
      secondMiniImage: "build1.png",
    },
    {
      text: (
        <>
          Make your own <b>builds </b>
          and <b>share them 3</b>
        </>
      ),
      characterImage: "meg-thomas.png",
      backImage: "cazadora.png",
      firstMiniImage: "build1.png",
      secondMiniImage: "build1.png",
    },
    {
      text: (
        <>
          Make your own <b>builds </b>
          and <b>share them 4</b>
        </>
      ),
      characterImage: "meg-thomas.png",
      backImage: "cazadora.png",
      firstMiniImage: "build1.png",
      secondMiniImage: "build1.png",
    },
  ];


  return (
    <>
      <Swiper
        pagination={pagination}
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        onSlideChange={(event) => setActiveSlide(event.activeIndex)}
      >
        {itemsCarousel.map(
          (
            {
              text,
              characterImage,
              backImage,
              firstMiniImage,
              secondMiniImage,
            },
            i
          ) => (
            <SwiperSlide key={i}>
              <Box
              display='flex'
                justifyContent="center"
                alignItems="center"
                height="100%"
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  px: { md: "2rem", lg: "0" },
                }}
              >
                {text ? (
                  <Typography
                    component="h2"
                    variant="h4"
                    color="#fff"
                    textAlign="center"
                    sx={{
                      width: {
                        xs: "100%",
                        md: "40%",
                      },
                      pb: { xs: "0rem", sm: "3rem", md: "" },
                    }}
                  >
                    {text}
                  </Typography>
                ) : null}
                <Box
                  sx={{
                    position: "relative",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    maxHeight: "500px",
                    height: "100%",
                    width: { xs: "60%", sm: "80%", md: "60%" },
                    mt: { xs: "-50px", sm: "" },
                    transform: { xs: "scale(.5)", sm: "scale(.8)", md: "none" },
                  }}
                >
                  <Image
                    fileName={characterImage}
                    folder="characters/complete"
                    alt="Meg Thomas"
                    csx={{
                      height: "90%",
                      position: "absolute",
                      left: "100px",
                      zIndex: 3,
                      bottom: 0,
                    }}
                  />
                  <Box>
                    <motion.svg
                      width="190"
                      height="100%"
                      viewBox="0 -10 150 460"
                      initial="hidden"
                      animate="visible"
                      style={{
                        height: "100%",
                        position: "absolute",
                        zIndex: 1,
                        left: "30px",
                        top: "-20px",
                        transform: "rotate(20deg)",
                      }}
                    >
                      <motion.rect
                        width="150"
                        height="440"
                        stroke="#fff"
                        strokeWidth="3"
                        fill="transparent"
                        variants={draw}
                        custom={0}
                        style={{ filter: "drop-shadow(0px 0px 6px #fff)" }}
                      />
                    </motion.svg>
                  </Box>
                  <Image
                    fileName={backImage}
                    folder="utils/login"
                    alt="recuadro"
                    csx={{
                      height: "100%",
                      position: "absolute",
                      zIndex: 2,
                      left: "120px",
                      top: "-20px",
                    }}
                  />
                  <Image
                    fileName={firstMiniImage}
                    folder="utils/login"
                    alt="recuadro"
                    csx={{
                      height: "120px",
                      position: "absolute",
                      zIndex: 4,
                      right: 0,
                      bottom: "180px",
                    }}
                  />
                  <Image
                    fileName={secondMiniImage}
                    folder="utils/login"
                    alt="recuadro"
                    className='animate__fadeIn animated'
                    csx={{
                      height: "120px",
                      position: "absolute",
                      zIndex: 4,
                      right: "60px",
                      bottom: "40px",
                    }}
                  />
                </Box>
              </Box>
            </SwiperSlide>
          )
        )}
      </Swiper>
    </>
  );
};

export default LoginCarousel;
