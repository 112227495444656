import {
  IconButton,
  InputBase,
  Paper,
  Typography,
  Button,
  Divider,
  styled,
  AppBar,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import React, { useContext } from "react";
import {
  Image,
  DialogCustom,
  PerkDetail,
  CustomTooltip,
  Spinner,
} from "@components";
import PropTypes from "prop-types";
import { AppContext } from "@application";
import { DrawerContext } from "@contexts/drawer/drawerContext";
import { PerkServices } from "@services";
import { TrendingFlat as TrendingFlatIcon } from "@mui/icons-material";
import "./perkSelector.scss";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { useEffect } from "react";

const ColorButton = styled(Button)(() => ({
  color: grey[50],
  borderColor: grey[50],
  "&:hover": {
    borderColor: grey[600],
  },
}));

const ShowPerkDetail = ({
  perk = null,
  component = "",
  selectPerk = null,
  drawer = null,
  content = null,
  insidePage = true,
}) => {
  return (
    <Box component="div" display="flex" flexDirection="column">
      {component === "drawer" ? (
        <AppBar
          position="fixed"
          sx={{
            top: "0",
            left: "0",
            width: "320px",
            height: "40px",
            justifyContent: "center",
            background: "transparent",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() =>
                drawer({
                  content: content.content,
                  open: false,
                  direction: content.direction,
                })
              }
              aria-label="close"
            >
              <TrendingFlatIcon
                fontSize="large"
                sx={{ transform: "scaleX(-1)" }}
              />
            </IconButton>
          </Toolbar>
        </AppBar>
      ) : null}
      {perk ? (
        <PerkDetail
          styles={{ pt: "10rem" }}
          name={perk.name}
          image={perk.id}
          detail={perk.description}
          width={component === "drawer" ? "320px" : "100%"}
        />
      ) : null}
      {insidePage && (
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          width={component === "drawer" ? "320px" : "100%"}
          py="1rem"
          position="fixed"
          bottom="0"
          left="0"
        >
          <Divider variant="middle" sx={{ borderColor: "#333", mb: "1rem" }} />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            gap="2rem"
          >
            <ColorButton
              variant="outlined"
              sx={{ width: "250px" }}
              onClick={() => selectPerk(perk)}
            >
              Select
            </ColorButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const PerkSelector = ({ role = "", insidePage = true }) => {
  const matchMd = useMediaQuery("(min-width:900px)");
  const { handleDrawer, drawerContent } = useContext(DrawerContext);
  const [searchText, setSearchText] = useState("");
  const [state, setState] = useContext(AppContext);
  const [perks, setPerks] = useState([]);
  const [loading, setLoading] = useState(false);
  const { slotIndex } = state.build;
  const [detail, setDetail] = useState({
    name: "",
    detail: "",
    open: false,
  });

  const selectPerk = (perk) => {
    if (isSelected(perk._id)) {
      return;
    }
    const perksTemp = [...state.build.selected];
    perksTemp.splice(slotIndex, 1, perk);
    setState({
      ...state,
      modal: {
        open: false,
        title: "",
      },
      build: {
        selected: perksTemp,
      },
    });

    handleDrawer({
      content: drawerContent.content,
      open: false,
      direction: drawerContent.direction,
    });

    openModalPerk(false);
  };

  const openDrawerDetail = ({
    content = null,
    open = true,
    direction = "",
  }) => {
    handleDrawer({ content: content, open: open, direction: direction });
    setState({
      ...state,
      drawer: {
        open: open,
      },
    });
  };

  const openModalPerk = ({ name = "", content = "", open = false }) => {
    setDetail({
      name,
      content,
      open,
      selectPerk: selectPerk,
    });
  };

  const isSelected = (_id) => {
    return state.build.selected.some((perk) => perk._id === _id);
  };

  const filterList = () => {
    return perks.filter(({ name }) =>
      name.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const loadData = async () => {
    setLoading(true);
    const perkData = await PerkServices.getPerks();
    setPerks(perkData.perks);
    setLoading(false);
    setState({
      ...state,
      perks: perkData.perks,
    });
  };

  const updatePerks = (perks) => {
    setPerks(perks);
  };

  useEffect(() => {
    const callPerks = () => {
      const perks = state.perks;
      perks.length === 0 ? loadData() : updatePerks(perks);
    };

    callPerks();
  }, []);

  return (
    <>
      {loading ? (
        <Box
          alignItems="center"
          justifyContent="center"
          display="flex"
          mt="5rem"
        >
          <Spinner />
        </Box>
      ) : (
        <Box
          sx={{ background: "#010b0f", py: "2rem", height: "100%" }}
          role="presentation"
        >
          <Box
            display="flex"
            position={insidePage ? "fixed" : "sticky"}
            zIndex="3"
            left="0"
            top={insidePage ? "60px" : "50px"}
            width="100%"
            py="1.5rem"
            sx={{ background: "#000b0f" }}
          >
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                maxWidth: "320px",
                mb: "2rem",
                mx: "auto",
                background: "transparent",
                borderBottom: "1px solid #fff",
                borderRadius: 0,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1, color: "#fff" }}
                placeholder="Search Perk"
                inputProps={{ "aria-label": "search google maps" }}
                value={searchText}
                onChange={({ target }) => setSearchText(target.value)}
              />
              <IconButton
                type="button"
                sx={{ p: "10px", color: "#fff" }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            flexWrap={"wrap"}
            justifyContent={"center"}
            gap={"1rem"}
            sx={{ p: insidePage ? "110px 1rem 1rem 1rem" : "1rem" }}
          >
            {filterList()
              .filter((e) => (role ? e.role === role : e))
              .map((perk, index) => (
                <CustomTooltip
                  className="cursor"
                  key={`tool${index}${perk.id}`}
                  title={
                    <Box
                      component="div"
                      display="flex"
                      sx={{ flexDirection: "column" }}
                      gap=".5rem"
                    >
                      <Typography component="h3" fontWeight="bold">
                        {perk.name}
                      </Typography>
                      <Typography component="h5" variant="body2">
                        {perk.description}
                      </Typography>
                      <ColorButton
                        variant="outlined"
                        sx={{ ml: "auto", color: "#fff", fontSize: ".65rem" }}
                        size="small"
                        onClick={() =>
                          matchMd
                            ? // version mobile
                              openDrawerDetail({
                                content: ShowPerkDetail({
                                  perk: perk,
                                  component: "drawer",
                                  selectPerk: selectPerk,
                                  drawer: handleDrawer,
                                  content: drawerContent,
                                  insidePage: insidePage,
                                }),
                                open: true,
                                direction: "left",
                              })
                            : // version desktop
                              openModalPerk({
                                name: perk.name,
                                content: perk,
                                open: true,
                              })
                        }
                      >
                        Ver más
                      </ColorButton>
                    </Box>
                  }
                >
                  <Box
                    sx={{ mr: "1rem", mb: "1rem" }}
                    key={`Box${index}${perk.id}`}
                    onClick={() =>
                      matchMd
                        ? // version mobile
                          openDrawerDetail({
                            content: ShowPerkDetail({
                              perk: perk,
                              component: "drawer",
                              selectPerk: selectPerk,
                              drawer: handleDrawer,
                              content: drawerContent,
                              insidePage: insidePage,
                            }),
                            open: true,
                            direction: "left",
                          })
                        : // version desktop
                          openModalPerk({
                            name: perk.name,
                            content: perk,
                            open: true,
                          })
                    }
                  >
                    <Box
                      component={"div"}
                      key={`border${index}${perk.id}`}
                      className={isSelected(perk._id) ? "box-border-perk" : ""}
                    >
                      <Image
                        className="cursor"
                        fileName={`${perk.id}.png`}
                        folder={"perks"}
                        alt={perk.name}
                        key={`${index}${perk.id}`}
                        csx={{
                          width: { xs: "80px", md: "100px" },
                        }}
                      />
                    </Box>
                  </Box>
                </CustomTooltip>
              ))}
          </Box>

          <DialogCustom
            open={detail.open}
            handleClose={() => openModalPerk(false)}
            title={detail.name}
          >
            {detail ? (
              <ShowPerkDetail
                perk={detail.content}
                component="modal"
                insidePage={insidePage}
                selectPerk={detail.selectPerk}
              />
            ) : null}
          </DialogCustom>
        </Box>
      )}
    </>
  );
};

PerkSelector.propTypes = {
  perks: PropTypes.array,
};

export default PerkSelector;
