import React, { forwardRef } from "react";
import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
  Slide,
} from "@mui/material";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import PropTypes from "prop-types";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='left' ref={ref} {...props} />;
});

const DialogCustom = ({
  open = false,
  fullScreen = true,
  handleClose,
  title = "",
  children,
  direction = 'left',
}) => {
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        direction={direction}
        fullScreen={fullScreen}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <AppBar
          sx={{
            position: "relative",
            background: "#000b0f",
            boxShadow: "0 0.5rem 1rem #000",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <TrendingFlatIcon
                fontSize="large"
                sx={{ transform: "scaleX(-1)" }}
              />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        
        <DialogContent
          dividers={true}
          sx={{ p: 0, background: "#010b0f", border: "none" }}
          id="alert-dialog-slide-description"
        >
          {children}
        </DialogContent>
      </Dialog>
    </>
  );
};

DialogCustom.propTypes = {
  open: PropTypes.bool,
  fullScreen: PropTypes.bool,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.any,
};

export default DialogCustom;
